import React, { useRef } from 'react';
import './ProjectItem.css'; // Ensure to create and link this CSS file
import useOnScreen from './useOnScreen'; // Adjust the import path as needed

const ProjectItem = ({ title, description,  logo, website }) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  return (
    <div className={`project-item ${isVisible ? 'fade-in' : ''}`} ref={ref}>
      <a href={website} target="_blank" rel="noopener noreferrer">
        <img src={logo} alt={`${title} logo`} className="project-logo" />
      </a>
        <p style={{padding:"2%"}}>{description}</p>
    </div>
  );
};

export default ProjectItem;
