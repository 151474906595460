import React from 'react';
import ITAzhur from '../../Assets/testimonials/IT Takatuf.jpg';
import DepartmentTakatuf from '../../Assets/testimonials/upscaled_business department takatuf.png';
import TestimonalItem from '../../Components/common/TestimonalItem';

const TestimonalData = [
  {
    id: 1,
    title: "Takatuf internal solution",
    description: "IT Support: 'Very quick in solving problems during their internal deployment'",
    src: ITAzhur
  },
  {
    id: 2,
    title: "Takatuf Business department ",
    description: "Exceptional speed delivery with High quality and Impressive work. Other departments appreciated the delivered work.",
    src: DepartmentTakatuf
  },
];

const Testimonials = () => {
  return (
    <>
        <div class="text-center topheader" style={{fontSize:25, padding:"1%"}}>Most recent testimonial, quick service coupled with high quality is rare!</div>
        <div className="testimonials-grid">
      {TestimonalData.map(testimonial => (
        <TestimonalItem 
          key={testimonial.id} 
          title={testimonial.title} 
          description={testimonial.description}
          src={testimonial.src} 
        />
      ))}
    </div>
    </>

  );
};

export default Testimonials;