import React from 'react';
import ServiceItem from '../../Components/common/ServiceItem';

const ServiceData = [
  {
    id: 1,
    title: "Startup basic web development solution",
    description: "Perfect package for startups and local businesses, includes homepage, services, about and contact pages.",
    price: "500 Omani Riyal/2 weeks.",
    type: "basic"
  },
  {
    id: 2,
    title: "Orders/Booking Management solution",
    description: "Whether you are a studio, a private school or a service provider, this solution will help you manage your orders or bookings easily including online payment.",
    price: "1000 Omani Riyal/4 weeks.",
    type: "management"
  },
  {
    id: 3,
    title: "Real Estate virtual tour and client communication solution",
    description: "Modern solution for real estate agents and agencies to showcase their properties and communicate with clients seemlessly.",
    price: "2500 Omani Riyal/7 weeks.",
    type: "realEstate"
  },
  {
    id: 4,
    title: "Completely Custom web development solution",
    description: "Usually custom tailored solution for large businesses and enterprises",
    price: "Price depends on user requirements",
    type: "custom"
  },
];

const Services = () => {
  return (
    <>
    <div class="text-center topheader" style={{fontSize:25, padding:"1%"}}>Special offers until the end <strong style={{fontStyle:"italic"}}>May 2024.</strong> <br></br> 25% payment upfront.</div>
    <div class="services-grid">
      {ServiceData.map(service => (
        <ServiceItem 
          key={service.id} 
          title={service.title} 
          description={service.description}
          price={service.price}
          type={service.type}
        />
      ))}
    </div>
    </>
    
  );
};

export default Services;
