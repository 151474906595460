import React, { useRef } from 'react';
import './Home.css';
import heroImage from './1.png';
import benefitImage1 from './2.png';
import benefitImage2 from './3.png';
import benefitImage3 from './4.png';
// import useOnScreenV2 from '../../Components/common/useOnScreenV2';
import useOnScreenV2 from './useOnScreenV2.js';
import { Link } from 'react-router-dom';

const Home = () => {
  const painPointsRef = useRef();
  const solutionRef = useRef();
  const benefitRef1 = useRef();
  const benefitRef2 = useRef();
  const benefitRef3 = useRef();
  const ctaRef = useRef();

  // Applying useOnScreenV2 for each ref, directly within the component.
  // useOnScreenV2 should handle adding a class or directly manipulating styles for animation.
  useOnScreenV2(painPointsRef);
  useOnScreenV2(solutionRef);
  useOnScreenV2(benefitRef1);
  useOnScreenV2(benefitRef2);
  useOnScreenV2(benefitRef3);
  useOnScreenV2(ctaRef);

  return (
    <div>
      <section ref={painPointsRef} className="pain-points topheader">
        <h2>Struggling with <span style={{color:"orange"}}>outdated</span> Order Management?</h2>
      </section>

      <section className="hero">
        <div className="hero-background">
          <img src={heroImage} alt="Hero" className="hero-image" />
          <div className="hero-overlay"></div>
          <div className="hero-content">
            <h1>Elevate Your Business with Techno Touches</h1>
          </div>
        </div>
      </section>

      <section ref={solutionRef} className="solution">
        <p>In today's fast-paced online world, manually tracking orders and relying on Excel spreadsheets just doesn't cut it anymore. It's time-consuming, error-prone, and hinders your business growth.</p>
        <div style={{height:"50px"}}></div>
        <h2>Simplify Your Order Management with Our Tailored Web Solution</h2>
        <ul style={{color:"green"}}>
          <li>Streamline your order processing and tracking.</li>
          <li>Gain valuable insights to optimize your business.</li>
          <li>Enhance customer satisfaction with efficient order management.</li>
        </ul>
      </section>

      <section className='benefits'>
        <h2>Why choose <div style={{display:"inline-block",color:"purple"}}>Quardafy?</div></h2>
        <div ref={benefitRef1} className="benefit">
          <img src={benefitImage1} alt="Benefit 1" className="benefit-image" />
          <h3>Exceptional Delivery Time</h3>
        </div>
        <div ref={benefitRef2} className="benefit">
          <img src={benefitImage2} alt="Benefit 2" className="benefit-image" />
          <h3>Cutting-Edge AI Integration</h3>
        </div>
        <div ref={benefitRef3} className="benefit">
          <img src={benefitImage3} alt="Benefit 3" className="benefit-image" />
          <h3>Custom Solutions</h3>
        </div>
      </section>

      <section ref={ctaRef} className="cta">
        <h2>Take Your Business to the Next Level</h2>
        <p>Ready to revolutionize your order management and boost your business growth?</p>
        <h5>Contact us today to discuss your project.</h5>
      </section>
    </div>
  );
};

export default Home;