import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div class="footer">
            <p>We are a web development agency dedicated to creating innovative and high-quality<br></br> websites for our clients.</p>
            <p>Phone: +968 95548584</p>
            <p>&copy; {new Date().getFullYear()} Web Development Agency. All rights reserved.</p>
    </div>
  );
};

export default Footer;