import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../Assets/NavBar.css';
import logoImg_mobile from '../../Assets/logo_arabic.png';
import logoImg_laptop from '../../Assets/logo_icon_notext.png';

const Header = () => {
  const [navActive, setNavActive] = useState(false);
  const location = useLocation(); // Get access to the location object
  const logoPath = window.innerWidth <= 768 ? logoImg_mobile : logoImg_laptop; // Check if window.innerWidth is less than or equal to 768 pixels

  const toggleNav = () => {
    // Check if window.innerWidth is less than or equal to 768 pixels
    if (window.innerWidth <= 768) {
      setNavActive(!navActive);
    }
  };

  // Optionally, you might want to close the nav when a link is clicked
  // This is useful for when a user selects an item on the mobile menu
  const closeNav = () => {
    if (window.innerWidth <= 768 && navActive) {
      setNavActive(false);
    }
  };

  const isServicesPage = location.pathname === '/services';
  const isBookingPage = /^\/booking($|\/).*/.test(location.pathname);
  
  useEffect(() => {
    // Disable scroll when nav is active and on mobile view
    if (navActive && window.innerWidth <= 768) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup function to reset overflow when component unmounts or updates
    return () => {
      document.body.style.overflow = '';
    };
  }, [navActive]);


  return (
    <header>
        <nav className={navActive ? '' : 'nav-inactive'}>
    <div className={`burger ${navActive ? 'active' : ''}`} onClick={toggleNav}>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <Link to="/" onClick={closeNav} className="logo-link" >
      <img src={logoPath} alt="Logo" className="logo" />
    </Link>
    <ul className={navActive ? 'nav-active' : ''}>
      <Link to="/" onClick={closeNav} ><img src={logoPath} alt="Logo" className="logo"/></Link>
      <li><Link to="/" onClick={closeNav}>Home</Link></li>
      
      <li><Link to="/services" onClick={closeNav}>Services</Link></li>
      <li><Link to="/testimonials" onClick={closeNav}>Testimonials</Link></li>
      <li><Link to="/projects" onClick={closeNav}>Projects</Link></li>
    </ul>
  </nav>

      {!isServicesPage && !isBookingPage && (
        <Link to="/services" className="services-btn-mobile" style={{display: window.innerWidth <= 768 ? "block" : "none", textDecoration:"none"}}>
          🕵️ Choose your package
        </Link>
      )}
    </header>
  );
};

export default Header;
