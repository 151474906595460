import React from 'react';
import ProjectItem from '../../Components/common/ProjectItem';
import siouxLogo from '../../Assets/projects/sioux-logo.png';
import insifyLogo from '../../Assets/projects/insify-logo.png';
import infolandLogo from '../../Assets/projects/infoland-logo.png';
import fontysLogo from '../../Assets/projects/fontys-logo.png';
import takatufLogo from '../../Assets/projects/takatuf-logo.png';

const projectData = [
  {
    id: 1,
    title: "Sixous",
    description: "Automated building door opening via camera car plate recognition AI and notify to the owner.",
    logo: siouxLogo,
    website: "https://www.sioux.eu/"
  },
  {
    id: 2,
    title: "Insify",
    description: "Dashboard software to reduce potential fraud claims using artificial intelligence.",
    logo: insifyLogo,
    website: "https://www.insify.nl/"
  },
  {
    id: 3,
    title: "Insify",
    description: "Reduce company risks by using online presence companies details to classify their activities and verify.",
    logo: insifyLogo,
    website: "https://www.insify.nl/"
  },
  {
    id: 4,
    title: "InfoLand",
    description: "Text to video artificial intelligence; A video (pre-determined character) with sound, visuals and emotions.",
    logo: infolandLogo,
    website: "https://zenya-software.com/"
  },
  {
    id: 5,
    title: "Fontys ICT",
    description: "Scalable enterprise; a questionare platform helps connecting job seekers with their perfect fit companies.",
    logo: fontysLogo,
    website: "https://zenya-software.com/"
  },
  {
    id: 6,
    title: "Takatuf",
    description: "Automated survey pipeline; internal system that fills customer details in Word document templates with style to responsible managers via email.",
    logo: takatufLogo,
    website: "https://takatuf.om/"
  },
];

const Projects = () => {
  return (
    <>
    <div class="text-center topheader" style={{fontSize:25, padding:"1%"}}>A glumse of completed projects between 2020 and 2024</div>
        <div className="projects-grid">
    {projectData.map(project => (
      <ProjectItem key={project.id} title={project.title} description={project.description} logo={project.logo} website={project.website} />
    ))}
  </div>
    </>


  );
};

export default Projects;
