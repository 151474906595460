import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import './ServiceItem.css';
import useOnScreen from './useOnScreen';

const ServiceItem = ({ title, description, price, type }) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleDiscoverDetailsClick = () => {
    navigate(`/booking/${type}`); // Navigate to /booking/{type}
  };

  return (
    <div className={`service-item ${isVisible ? 'fade-in' : ''}`} ref={ref}>
      <h3 className="service-title">{title}</h3>
      <p className="service-price">{price}</p>
      <p className="service-description">{description}</p>
      <button className="learn-more-btn" onClick={handleDiscoverDetailsClick}>Discover details</button>
    </div>
  );
};

export default ServiceItem;
