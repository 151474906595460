import React, { useRef } from 'react';
import useOnScreen from './useOnScreen';
import './TestimonialItem.css';

const TestimonialItem = ({ title, description, src }) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  return (
    <div className={`testimonial-item ${isVisible ? 'animate-on-screen' : ''}`} ref={ref}>
      <div className="testimonial-content">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="testimonial-image" style={{textAlign:"center"}}>
        <img src={src} alt={`Testimonial by ${title}`} />
      </div>
    </div>
  );
};

export default TestimonialItem;
