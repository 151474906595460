import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/layout/Header';
import Footer from './Components/layout/Footer';
import Home from './Pages/Home';
import Projects from './Pages/Projects';
import Services from './Pages/Services';
import Testimonials from './Pages/Testimonials';
import Booking from './Pages/Booking';


const App = () => {
  return (
    <Router>
      <div className="content">
      <div className="app-content">
    <div className="main-content">
      <div className="text-center highlights">
        <span>
          ✅ Exceptional delivery time
          ✅ Cutting edge AI integration
          ✅ Custom solutions
        </span>
      </div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/projects" element={<Projects />} exact />
        <Route path="/services" element={<Services />} exact />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/booking/:type" element={<Booking />} />
      </Routes>
    </div>
    <Footer />
  </div>
      </div>
</Router>);
};

export default App;