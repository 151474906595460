import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './Booking.css';

function getDateString() {
    const date = new Date();

    let day = date.getDate().toString().padStart(2, '0'); // Ensures the day is two digits
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensures the month is two digits
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

const Booking = () => {
    // Get the current date and time
    const now = new Date();

    // Format the date and time
    const dateString = now.toDateString(); // "Thu Apr 06 2024"
    const timeString = now.toTimeString(); // "00:00:00 GMT+0000 (UTC)"
    const createdAt = dateString+timeString;


    const { type } = useParams(); // Extract 'type' from URL
    const currentDate = getDateString(); 
    const [formData, setFormData] = useState({
        phoneNumber: '',
        date: currentDate,
        email: '',
        description: ''
    });

    const [loading, setLoading] = useState(false); // Loading state
    const [message, setMessage] = useState(''); // Message to the user

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        setMessage(''); // Reset message
        
        try {
            const response = await fetch('https://wild-goat-pleat.cyclic.app/api/insert-contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phoneNumber: formData.phoneNumber,
                    date: formData.date,
                    email: formData.email,
                    description: formData.description,
                    type: type, // Include this if the API expects 'type' in the payload
                    createdAt:createdAt
                }),
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data); // Output the response from the server
                setMessage('Your request has been successfully sent.');
            } else {
                // Handle non-2xx responses (like 404 or 500 errors)
                throw new Error('Booking is full at the moment, reach out to us by phone.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setMessage('Booking is full at the moment, try again later.');
        } finally {
            setLoading(false); // End loading
        }
    };

    return (
        <>
            <div class="text-center topheader" style={{fontSize:25, padding:"1%"}}>Let's transformyour business needs into a reality.</div>
            <div className="booking-container">
            <h1>Book a discussion appointment</h1>
            <form onSubmit={handleSubmit}>
                <input required type="text" name="phoneNumber" placeholder="Phone Number" onChange={handleInputChange} />
                <input required type="date" name="date" value={formData.date} onChange={handleInputChange}/>
                <input required type="email" name="email" placeholder="Email" onChange={handleInputChange} />
                <textarea required name="description" placeholder="Description" onChange={handleInputChange}></textarea>
                <button type="submit" disabled={loading}>{loading ? 'Submitting...' : 'Book Now'}</button>
            </form>
            {message && <p className="booking-message">{message}</p>}
        </div>
        </>
    );
};
export default Booking;
